


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';

import { mapGetters, mapState} from 'vuex';
// @ts-ignore
import { TContact } from '@/_types/contact.type';
import { TUser } from '@/_types/user.type';
import { TEvent } from '@/_types/event.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import EventHelper from '@/_helpers/event.helper';
import Avatar from '@/_components/avatar/avatar.vue';
import sideBarContactCard from '@/_modules/promo/components/side-bar-contact-card/side-bar-contact-card.vue';
import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';
import IconCorrespondence from '@/_modules/icons/components/sidebar/icon-correspondence.vue';
import IconSchedule from '@/_modules/icons/components/sidebar/icon-schedule.vue';
import IconNotifications from '@/_modules/icons/components/sidebar/icon-notifications.vue';
// import VueI18n from 'vue-i18n';
// import TranslateResult = VueI18n.TranslateResult;

enum TabNames {
  NONE = '_nothing_',
  NOTIFICATIONS = 'notifications',
  CORRESPONDENCE = 'correspondence',
  SCHEDULE = 'SCHEDULE',
  CONTACT = 'contact',
}

@Component({
  name: 'promo-side-bar-right',
  components: {
    Avatar,
    sideBarContactCard,
    IconArrowLeft,
    IconCorrespondence,
    IconSchedule,
    IconNotifications
  },
  computed: {
    ...mapState('userStore', {
      userInfo: 'user_info'
    }),
    ...mapGetters('_eventStore', {
      event: 'event'
    }),
    ...mapState('contactStore', {
      contactInfo: 'contact'
    }),
    ...mapGetters('promoPageStore', {
      promoPage: 'promoPage',
    }),
    ...mapGetters('sideBarRightStore', {
      isOpen: 'isOpen',
    }),
  },
})
export default class PromoSideBarRight extends Vue {

  // DATA
  public TabNames: typeof TabNames = TabNames;
  public sideBarActiveTabName: TabNames = TabNames.NONE;

  // mapped
  public readonly isOpen: boolean;
  public readonly userInfo: TUser;
  public readonly event: TEvent;
  public readonly promoPage: TPromoPage;
  public readonly contactInfo: TContact;

  // COMPUTED
  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get canEditCompany(): boolean {
    if (!this.promoPage || !this.contactInfo || !this.promoPage.attached_contacts) {
      return false;
    }

    const contact: any = this.promoPage.attached_contacts.find((item) => {
      return item.contact.id === this.contactInfo.id;
    });

    if (!contact) {
      return false;
    }

    return (contact.role === 'owner' || contact.role === 'assistant' || contact.role === 'admin');
  }

  public get isEventOrganizer(): boolean {
    return EventHelper.isContactEventOrganizer(this.event, this.contactInfo);
  }

  public get contactFullName(): string {
    if (!this.contactInfo) {
      return '';
    }
    let result = [this.contactInfo.name, this.contactInfo.surname].filter(x => x).join(' ');
    if (!result) {
      result = this.$t('contacts.info.noname') as string;
    }
    return result;
  }

  public get contactGeoInfo(): string {
    if (!this.contactInfo) {
      return '';
    }
    return [this.contactInfo.city, this.contactInfo.country].filter(x => x).join(', ');
  }

  public get contactCompanyInfo(): string {
    if (!this.contactInfo) {
      return '';
    }
    return [this.contactInfo.company_position, this.contactInfo.company_name].filter(x => x).join(', ');
  }

  public get promoPageGeoInfo(): string {
    if (!this.promoPage) {
      return '';
    }
    return [this.promoPage.city, this.promoPage.country].filter(x => x).join(', ');
  }

  public get promoPageDescription(): string {
    if (!this.promoPage || !this.promoPage.description) {
      return '';
    }
    const limit = 43;
    const isTruncated = this.promoPage.description.length > limit;
    return this.promoPage.description.substring(0, limit) + (isTruncated ? '…' : '');
  }

  public get contactInitials(): string {
    const defaultInitialsText = '';
    if (!this.contactInfo) {
      return defaultInitialsText;
    } else if (!this.contactInfo.name || !this.contactInfo.surname) {
      return defaultInitialsText;
    }
    return this.contactInfo.name.substring(0, 1).toUpperCase() + this.contactInfo.surname.substring(0, 1).toUpperCase();
  }

  // PROPS

  @Prop({ type: Boolean, default: false })
  public readonly isAuthenticated: boolean;

  // WATCH

  // HOOKS

  // METHODS

  private unfoldTheSideBar(): void {
    this.$store.dispatch('sideBarRightStore/open');
  }

  private foldTheSideBar(): void {
    this.$store.dispatch('sideBarRightStore/close');
    this.sideBarActiveTabName = TabNames.NONE;
  }

  private toggleTheSideBar(): void {
    this.$store.dispatch('sideBarRightStore/toggle');
  }

  private onMenuItemClick(tabName: TabNames): void {
    if (!this.isOpen) {
      this.unfoldTheSideBar();
    } else if (tabName === this.sideBarActiveTabName) {
      this.toggleTheSideBar();
      tabName = TabNames.NONE;
    }
    this.sideBarActiveTabName = tabName;
  }

  private openEditContact(): void {
    if (this.$route.name !== 'promo-page-edit-contact') {
      this.$router.push({ name: 'promo-page-edit-contact' });
    }
  }

  private openEditCompany(): void {
    if (this.$route.name !== 'promo-page-edit-company') {
      this.$router.push({ name: 'promo-page-edit-company' });
    }
  }

  private openOrganizerCabinet(): void {
    if (this.$route.name !== 'promo-page-cabinet-main-page') {
      this.$router.push({ name: 'promo-page-cabinet-main-page' });
    }
  }

  private async logout(): Promise<void> {
    await this.$store.dispatch('authStore/authLogout');
    await this.$router.push({ name: 'auth-page', params: { forceAuthPageRoute: 'true' } });
  }

}
