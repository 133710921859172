


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

@Component({
  name: 'side-bar-contact-card',
})
export default class PromoSideBarRight extends Vue {

  // DATA
  public tempData: boolean = true;

  // mapped

  // COMPUTED

  // PROPS
  @Prop({ type: String, default: '' })
  public readonly cardTitle: TranslateResult;

  // WATCH

  // HOOKS

  // METHODS

  private emitEditEvent(): void {
    this.$emit('editButtonPressed');
  }

}
